/* eslint-disable no-return-assign */
import { ref } from 'vue'
import type { Ref } from 'vue'

export interface FrameSelection {
  unitVideoSec: number,
  sessionVideoPts: number,
}

interface AuditLibrary {
  isGivenFrameRequestedForAnnotation: Ref<boolean>
  frameSelections: Ref<FrameSelection[]>
  requestAnnotationForFrame: () => void
  removeAnnotationRequest: () => void
  updateMediaTime: (time: number) => void
}

export const useAuditLibrary = (): AuditLibrary => {
  const frameSelections: Ref<FrameSelection[]> = ref([])
  const isGivenFrameRequestedForAnnotation = ref(false)
  const mediaTime = ref(0)

  const checkIfCurrentFrameRequestedAnnotation = () => {
    isGivenFrameRequestedForAnnotation.value = false

    const isRequested = frameSelections.value.some((frame) => frame.unitVideoSec === mediaTime.value)
    if (isRequested) isGivenFrameRequestedForAnnotation.value = true
  }

  const requestAnnotationForFrame = () => {
    const isRequested = frameSelections.value.some((frame) => frame.unitVideoSec === mediaTime.value)

    if (!isRequested) {
      frameSelections.value.push({
        unitVideoSec: mediaTime.value,
        sessionVideoPts: 0,
      })

      isGivenFrameRequestedForAnnotation.value = true
    }
  }

  const removeAnnotationRequest = () => {
    frameSelections.value = frameSelections.value.filter(({ unitVideoSec }) => unitVideoSec !== mediaTime.value)

    isGivenFrameRequestedForAnnotation.value = false
  }

  const updateMediaTime = (time: number) => {
    mediaTime.value = time
    checkIfCurrentFrameRequestedAnnotation()
  }

  return {
    isGivenFrameRequestedForAnnotation,
    frameSelections,
    requestAnnotationForFrame,
    removeAnnotationRequest,
    updateMediaTime,
  }
}
